import {Component, input, Signal} from '@angular/core';
import {AlgoliaService} from '@core/services/algolia.service';
import {from, Observable} from 'rxjs';
import {RoutingService} from '@core/services/routing.service';
import {StoryblokService} from '@core/services/storyblok.service';
import {StoryblokStylingService} from '@core/services/storyblok-styling.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {HeaderMenuItemComponent} from './header-menu-item/header-menu-item.component';
import {
  InstantsearchHierarchicalMenuComponent
} from '../instantsearch-hierarchical-menu/instantsearch-hierarchical-menu.component';
import {NgClass, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {HeaderMenuSubMenuComponent} from './header-menu-sub-menu/header-menu-sub-menu.component';
import {environment} from '@env/environment';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {toSignal} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-header-bottom',
  templateUrl: './header-bottom.component.html',
  styleUrls: ['./header-bottom.component.scss'],
  standalone: true,
  imports: [NgOptimizedImage, NgClass, NgForOf, NgIf, HeaderMenuItemComponent, InstantsearchHierarchicalMenuComponent, HeaderMenuSubMenuComponent, InstantSearchComponent]
})
export class HeaderBottomComponent {

  menuStoryBlok: Signal<any>

  generalStylingElements: { [uid: string]: string } = {};

  config = this.algoliaService.searchConfig(environment.algolia.algoliaProxy);
  isCollapsed = input<boolean|null>(null)

  constructor(
    private algoliaService: AlgoliaService,
    private routingService: RoutingService,
    private storyblokService: StoryblokService,
    private storyblokStylingService: StoryblokStylingService
  ) {
    this.menuStoryBlok = toSignal(this.getMenuStoryBlokObservable().pipe(
      map((data: any) => data?.story),
      tap(() => {
        this.generalStylingElements = {};
      })
    ))
  }

  getMenuClassName(menuStructure: any): string {
    const uid = menuStructure?._uid;
    if (!uid) {
      return '';
    }
    if (!this.generalStylingElements[uid]) {
      const classList = this.storyblokStylingService.getClassList(menuStructure.general_styling)?.join(' ');
      this.generalStylingElements[uid] = classList;
    }
    return this.generalStylingElements[uid];
  }

  getMenuStoryBlokObservable() {
    const queryParam = this.routingService.getQueryParams();
    if (this.storyblokService.isPreviewStoryblokPage(this.routingService.getQueryParams())) {
      return this.getPreviewStory(queryParam, 'menu')
    } else {
      return from(this.getStory(queryParam, 'menu'))
    }
  }

  protected getStory(queryParam: Record<string, string>, slug: string): Promise<any> {
    return this.storyblokService.getStoryPromise(slug, queryParam);
  }

  protected getPreviewStory(queryParam: Record<string, string>, slug: string): Observable<any> {
    const promiseStory = this.getStory(queryParam, slug);

    return this.storyblokService.storyblokMenuInputChange.pipe(
      switchMap((data) => {
        return this.storyblokService.storyblokEditCheck(data, promiseStory)
      })
    );
  }

  getMenuType(menuStructure: any) {
    if (menuStructure.component === 'AlgoliaMenu') {
      return 'AlgoliaMenu';
    } else if (menuStructure.sub_links?.length > 0) {
      return 'subMenu';
    } else {
      return 'menu';
    }
  }

  menuTracker(index: any, item: any) {
    return item.id;
  }

}
