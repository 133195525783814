export const environment = {
  production: true,
  sentry: {
    DSN: 'https://5dacfd4b26a142bcbf1a3bbacf4db121@o4504288101728256.ingest.sentry.io/4504367019655168',
    environment: 'Production',
    enabled: true,
  },
  algolia: {
    appId: '5G37W5YM1S',
    publicKey: 'e4300a2d1174aea46eccab87123c4fe0',
    defaultIndex: 'Products',
    algoliaProxy: 'algolia.supervin.dk',
    wineFestivalIndexName: 'Products_VF'
  },
  supervin: {
    api: 'https://magento.supervin.dk/',
    graphQLPath: 'efiware/page/graphql',
    storyblokPath: 'storyblok/storyblok/story',
    assets: 'https://magento.supervin.dk',
    checkout: 'https://checkout.supervin.dk/',
    persistentCache: true,
    freeDelivery: 1000,
    frontendHostname: 'www.supervin.dk',
    previewHostname: 'preview.supervin.dk',
    showPowerStop: false,
    disableCityField: true,
  },
  superpay: {
    enabled: true,
    domain: 'supervin',
    api: 'https://superpay.supervin.dk/api',
    graphql: 'https://5flxygi5pd.execute-api.eu-central-1.amazonaws.com/prod/graphql'
  },
  GTM: {
    id: 'GTM-KTHS2PG'
  },
  storyblok: {
    enforceDraft: false,
    public_accessToken: 'FncW5BWyJqewAMrX5Sx3EQtt'
  },
  oneSignal: {
    appId: '038a85e0-de36-410e-8da2-b94f804fe9bc'
  },
  cvrapi: {
    url: 'https://cvrapi.dk/api',
  },
  serverPort: 80
};
