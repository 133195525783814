<div class="container">
  <div class="wrapper">
    <app-instantsearch *ngIf="config() as conf" [config]="conf">
      <app-instantsearch-configure [searchParameters]="configuration"></app-instantsearch-configure>
      <app-wine-festival-search-box
        placeholder="000"
      ></app-wine-festival-search-box>
      <app-wine-festival-infinite-hits></app-wine-festival-infinite-hits>
    </app-instantsearch>
    <div class="btn-go-to-cart">
      <button (click)="goToCart()" class="btn btn-primary">Gå til kurven</button>
    </div>
  </div>
</div>
