import {Injectable} from '@angular/core';
import {
  StoryblokProductCarouselComponent
} from '@modules/storyblok/components/storyblok-product-carousel/storyblok-product-carousel.component';
import {StoryblokVideoComponent} from '@modules/storyblok/components/storyblok-video/storyblok-video.component';
import {StoryblokRowComponent} from '@modules/storyblok/components/storyblok-row/storyblok-row.component';
import {StoryblokTextComponent} from '@modules/storyblok/components/storyblok-text/storyblok-text.component';
import {StoryblokCardComponent} from '@modules/storyblok/components/storyblok-card/storyblok-card.component';
import {
  StoryblokProductSliderComponent
} from '@modules/storyblok/components/storyblok-product-slider/storyblok-product-slider.component';
import {StoryblokSpotsComponent} from '@modules/storyblok/components/storyblok-spots/storyblok-spots.component';
import {StoryblokPageComponent} from '@modules/storyblok/components/storyblok-page/storyblok-page.component';
import {
  StoryblokAlgoliaFilterComponent
} from '@modules/storyblok/components/storyblok-algolia-filter/storyblok-algolia-filter.component';
import {
  StoryblokImageTitleComponent
} from '@modules/storyblok/components/storyblok-image-title/storyblok-image-title.component';
import {
  StoryblokContainerComponent
} from '@modules/storyblok/components/storyblok-container/storyblok-container.component';
import {StoryblokVimeoComponent} from '@modules/storyblok/components/storyblok-vimeo/storyblok-vimeo.component';
import {StoryblokGroupComponent} from '@modules/storyblok/components/storyblok-group/storyblok-group.component';
import {StoryblokTinymceComponent} from '@modules/storyblok/components/storyblok-tinymce/storyblok-tinymce.component';
import {
  StoryblokProductListComponent
} from '@modules/storyblok/components/storyblok-product-list/storyblok-product-list.component';
import {
  StoryblokBuyGiftcardComponent
} from '@modules/storyblok/components/storyblok-buy-giftcard/storyblok-buy-giftcard.component';
import {
  StoryblokHubspotFormComponent
} from '@modules/storyblok/components/storyblok-hubspot-form/storyblok-hubspot-form.component';
import {
  StoryblokTrendingProductsComponent
} from '@modules/storyblok/components/storyblok-trending-products/storyblok-trending-products.component';
import {
  StoryblokWineFestivalOrderingComponent
} from '@modules/storyblok/components/storyblok-wine-festival-ordering/storyblok-wine-festival-ordering.component';
import {StoryblokImageComponent} from '@modules/storyblok/components/storyblok-image/storyblok-image.component';
import {
  StoryblokProductCardComponent
} from '@modules/storyblok/components/storyblok-product-card/storyblok-product-card.component';
import {
  StoryblokCountdownComponent
} from '@modules/storyblok/components/storyblok-countdown/storyblok-countdown.component';
import {
  StoryblokIconWithTextComponent
} from '@modules/storyblok/components/storyblok-icon-with-text/storyblok-icon-with-text.component';

@Injectable({
  providedIn: 'root'
})
export class StoryblokComponentService {

  getComponent(type: any): any {
    const components: any = this.getComponents()
    return components[type];
  }

  getComponents() {
    return  {
      container: StoryblokContainerComponent,
      page: StoryblokPageComponent,
      row: StoryblokRowComponent,
      card: StoryblokCardComponent,
      video: StoryblokVideoComponent,
      vimeo: StoryblokVimeoComponent,
      text: StoryblokTextComponent,
      spots: StoryblokSpotsComponent,
      image: StoryblokImageComponent,
      countdown: StoryblokCountdownComponent,
      'icon-with-text': StoryblokIconWithTextComponent,
      'product-card': StoryblokProductCardComponent,
      'product-carousel': StoryblokProductCarouselComponent,
      'product-list': StoryblokProductListComponent,
      'product-slider': StoryblokProductSliderComponent,
      'full-page': StoryblokPageComponent,
      'algolia-filter': StoryblokAlgoliaFilterComponent,
      'image-title': StoryblokImageTitleComponent,
      group: StoryblokGroupComponent,
      tinymce: StoryblokTinymceComponent,
      'buy-giftcard': StoryblokBuyGiftcardComponent,
      'hubspot-form': StoryblokHubspotFormComponent,
      'trending-products': StoryblokTrendingProductsComponent,
      'wine-festival-ordering': StoryblokWineFestivalOrderingComponent,
    };
  }
}
