import {Component, effect, input, signal, WritableSignal} from '@angular/core';
import {NgIf} from '@angular/common';
import {ProductCarouselComponent} from '@layout/components/product/product-carousel/product-carousel.component';
import {AlgoliaRecommendService} from '@core/services/algolia-recommend.service';
import {AlgoliaService} from '@core/services/algolia.service';
import {AlgoliaWineProduct} from '@core/types/algolia-wine-product';

@Component({
  selector: 'app-product-trending-items',
  templateUrl: './product-trending-items.component.html',
  styleUrls: ['./product-trending-items.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ProductCarouselComponent
  ]
})
export class ProductTrendingItemsComponent {

  trendingItems: WritableSignal<AlgoliaWineProduct[]> = signal([]);

  facetName = input<string | undefined>();
  facetValue = input<string | undefined>();
  maxRecommendations = input<number>(9);

  constructor(
    private algoliaRecommendService: AlgoliaRecommendService,
    protected algoliaService: AlgoliaService
  ) {
    effect(async () => {
      const key = this.algoliaService.alogliaApiKey();
      const maxRecommendations = this.maxRecommendations();
      const facetName = this.facetName();
      const facetValue = this.facetValue();

      if (!key) {
        return;
      }

      this.trendingItems.set(await this.algoliaRecommendService.getTrendingProducts(key, maxRecommendations, '', facetName, facetValue));
    }, {allowSignalWrites: true});
  }
}
