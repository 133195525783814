import {Component} from '@angular/core';
import {NgIf} from '@angular/common';
import {AlgoliaService} from '@core/services/algolia.service';
import {SearchParameters} from 'algoliasearch-helper';
import {WineFestivalSearchBoxComponent} from './components/wine-festival-search-box/wine-festival-search-box.component';
import {
  WineFestivalInfiniteHitsComponent
} from './components/wine-festival-infinite-hits/wine-festival-infinite-hits.component';
import {Router} from '@angular/router';
import {environment} from '@env/environment';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {InstantSearchConfigureComponent} from '@core/instantsearch/components/instant-search-configure.component';

@Component({
  selector: 'app-storyblok-wine-festival-ordering',
  templateUrl: './storyblok-wine-festival-ordering.component.html',
  styleUrls: ['./storyblok-wine-festival-ordering.component.scss'],
  standalone: true,
  imports: [
    WineFestivalSearchBoxComponent,
    WineFestivalInfiniteHitsComponent,
    InstantSearchComponent,
    InstantSearchConfigureComponent,
    NgIf
  ]
})
export class StoryblokWineFestivalOrderingComponent {

  config = this.algoliaService.searchConfig(undefined, false, environment.algolia.wineFestivalIndexName);
  configuration: SearchParameters = {
    hitsPerPage: 20,
    filters: 'has_vf_value: true',
  } as any;

  constructor(
    private algoliaService: AlgoliaService,
    private router: Router,
  ) {
  }

  goToCart() {
    this.router.navigateByUrl('/checkout/cart');
  }

}
