import {Injectable} from '@angular/core';
import {CookieService} from './cookie.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlgoliaRecommendService} from './algolia-recommend.service';
import {CartService} from './cart.service';
import {environment} from '@env/environment';
import {captureException} from '@sentry/angular-ivy';
import {TrackEventService} from '@core/services/track-event.service';

@Injectable({
  providedIn: 'root'
})
export class PowerStopService {

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private modalService: NgbModal,
    private algoliaRecommendService: AlgoliaRecommendService,
    private cartService: CartService,
    private trackEventService: TrackEventService
  ) {
  }

  goToPowerStop(eventName: string) {
    if (this.cookieService.get('powerStop') || !environment.supervin.showPowerStop) {
      this.trackEventService.trackEvent(eventName, {powerStop: false})
      this.goToCart(eventName);
      return;
    }
    const expiresDate = this.cookieService.getExpiresDate(0, 1);
    this.cookieService.set('powerStop', '1', expiresDate, '/');
    this.trackEventService.trackEvent(eventName, {powerStop: true})
    this.openPowerStopModal(eventName);
  }

  async openPowerStopModal(eventName: string) {
    try {
      const cart = this.cartService.currentCart();
      const productIds = cart.cart.items?.map(product => product?.sku).filter(sku => sku);
      const filter = productIds?.map(sku => `NOT product_sku:${sku}`).join(' AND ');

      const algoliaApiKey = this.algoliaRecommendService.algoliaApiKey()

      if (!algoliaApiKey) {
        return;
      }

      const recommendations = await this.algoliaRecommendService.getTrendingProducts(algoliaApiKey, 12, filter);
      import('../../content/modules/checkout/components/checkout-power-stop/checkout-power-stop.component').then(comp => {
        const modalReference = this.modalService.open(comp.CheckoutPowerStopComponent, {
          modalDialogClass: 'modal-dialog-bottom modal-large'
        });
        modalReference.componentInstance.trendingProducts = recommendations;
      })
    } catch (e) {
      console.error(e);
      captureException('PowerStop error: ' + e);
      this.goToCart(eventName);
    }
  }

  private goToCart(eventName: string) {
    this.modalService.dismissAll();
    this.trackEventService.routerContext = {eventName};
    this.router.navigateByUrl('/checkout/cart');
  }
}
