import {Component, effect, EventEmitter, input, Output} from '@angular/core';
import recommend from '@algolia/recommend';
import {environment} from '@env/environment';
import {RecommendationsQuery} from '@algolia/recommend/dist/recommend';
import {AsyncPipe, NgIf} from '@angular/common';
import {ProductCarouselComponent} from '@layout/components/product/product-carousel/product-carousel.component';
import {AlgoliaService} from '@core/services/algolia.service';
import {from, Observable} from 'rxjs';

@Component({
  selector: 'app-product-recommendations',
  templateUrl: './product-recommendations.component.html',
  styleUrls: ['./product-recommendations.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    ProductCarouselComponent
  ]
})
export class ProductRecommendationsComponent {

  recommendations$: Observable<any>;

  objectID = input.required<string>();

  constructor(
    private algoliaService: AlgoliaService
  ) {
    effect(() => {
      const apiKey = this.algoliaService.alogliaApiKey();
      const objectID = this.objectID();
      if (!apiKey) {
        return;
      }
      this.recommendations$ = from(this.getRecommendations(objectID, apiKey));
    });
  }

  @Output() hitsChange: EventEmitter<number> = new EventEmitter<number>();

  async getRecommendations(objectID: string, publicKey: string) {
    const recommendClient = recommend(environment.algolia.appId, publicKey);
    const indexName = environment.algolia.defaultIndex;

    const recommendationsQuery: RecommendationsQuery = {
      indexName,
      objectID: objectID,
      model: 'related-products',
      maxRecommendations: 6,
    }

    const recommendations = await recommendClient.getRecommendations([recommendationsQuery])
    const hits = (recommendations.results[0] as any).hits;

    this.hitsChange.emit(hits.length);

    return hits.map((hit: any) => {
      return this.algoliaService.hitToWineProduct(hit);
    });
  }
}
