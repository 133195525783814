import {ChangeDetectionStrategy, Component, effect, input} from '@angular/core';
import {StoryblokDirective} from '@core/directives/storyblok.directive';
import {YouTubePlayerModule} from '@angular/youtube-player';
import {
  ProductTrendingItemsComponent
} from '../../../page/components/product/components/product-trending-items/product-trending-items.component';
import {SearchPageComponent} from '@modules/page/components/search-page/search-page.component';
import {AlgoliaRecommendService} from '@core/services/algolia-recommend.service';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import {ListPageObjectIds} from '@core/interfaces/generated/graphql';
import {AlgoliaService, CustomProductListFilter} from '@core/services/algolia.service';

@Component({
  selector: 'app-storyblok-trending-products',
  templateUrl: './storyblok-trending-products.component.html',
  styleUrls: ['./storyblok-trending-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StoryblokDirective, YouTubePlayerModule, ProductTrendingItemsComponent, SearchPageComponent, AsyncPipe]
})
export class StoryblokTrendingProductsComponent {

  viewType = input<'list' | 'carousel'>('carousel');
  facetName = input<string | undefined>();
  facetValue = input<string | undefined>();
  maxRecommendations = input<number>(9);
  _editable = input<any>();

  trendingItems$: Observable<CustomProductListFilter>;

  constructor(
    private algoliaRecommendService: AlgoliaRecommendService,
    protected algoliaService: AlgoliaService,
  ) {
    effect(() => {
      const key = this.algoliaService.alogliaApiKey();
      const maxRecommendations = this.maxRecommendations();
      const facetName = this.facetName();
      const facetValue = this.facetValue();

      if (!key) {
        return;
      }

      this.trendingItems$ = from(this.algoliaRecommendService.getTrendingProducts(key, maxRecommendations, '', facetName, facetValue)).pipe(
        map((products) => {
          const objectIds = products.map((product) => {
            return {vismaId: product.vismaProductNumber, objectId: 0, weight: 0} as ListPageObjectIds
          });
          return {
            types: [],
            objectIds: objectIds,
            title: 'Trending Products',
            storyblok: undefined
          } as CustomProductListFilter
        })
      );
    });
  }

}
