import {computed, Injectable, Signal} from '@angular/core';
import {ApolloQueryResult} from '@apollo/client/core';
import {Query, StoreConfig} from '@core/interfaces/generated/graphql';
import {ApolloService} from '@core/services/apollo.service';

@Injectable({
  providedIn: 'root'
})
export class StoreConfigService {


  protected _storeConfigResult: Signal<ApolloQueryResult<Query> | undefined> = this.apollo.watchQuerySignal<Query>({
    queryName: 'getStoreConfig',
  });

  protected _storeConfig = computed(() => {
    return this._storeConfigResult()?.data.getStoreConfig;
  })


  constructor(
    private apollo: ApolloService,
  ) {
  }

  get storeConfig(): Signal<StoreConfig | undefined> {
    return this._storeConfig;
  }

}
