import {Component, EventEmitter, OnDestroy, Output, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {filter, map, tap} from 'rxjs/operators';
import {SearchPageComponent} from '../search-page/search-page.component';
import {PageInfoService} from '@core/services/page-info.service';
import {RoutingService} from '@core/services/routing.service';
import {PageInfo} from '@core/interfaces/generated/graphql';
import {PageService} from '@core/services/page.service';
import {AsyncPipe, NgIf} from '@angular/common';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-search-page-algolia',
  templateUrl: './search-page-algolia.component.html',
  styleUrls: ['./search-page-algolia.component.scss'],
  standalone: true,
  imports: [SearchPageComponent, NgIf, AsyncPipe]
})
export class SearchPageAlgoliaComponent implements OnDestroy {

  private combineKey = 'Products[query]';

  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchPage', {static: true}) searchPage: SearchPageComponent;

  combine$: Observable<string>;
  combine: string;
  pageInfo$: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private pageService: PageService,
    private pageInfoService: PageInfoService,
    private routingService: RoutingService,
  ) {
    this.getPageInfo(
      this.routingService.getURL(undefined, false),
      this.routingService.getQueryParams()['secret_key']
    );

    this.combine$ = this.activeRoute.queryParams.pipe(
      filter(params => {
        return !!params[this.combineKey];
      }),
      map(params => {
        return params[this.combineKey];
      }),
      tap((combine) => {
        this.dataChange.emit({id: 'search-page', __typename: 'page'});
        const isFirst = !this.combine;
        this.combine = combine;
        if (!isFirst) {
          this.updateUiState();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.pageInfo$?.unsubscribe();
  }

  protected getPageInfo(path: string, secret?: string) {
    this.pageInfo$ = this.pageInfoService.pageInfoGraphQL(path, secret).pipe(
      tap(data => {
        if (data) {
          this.setPage(data);
        }
      })
    ).subscribe();
  }

  protected setPage(data: PageInfo) {
    this.pageService.setPage(this.pageInfoService.getPageData(data));
  }

  protected updateUiState() {
    const instance = this.searchPage?.instantSearch?.instantSearchInstance as any;
    const routing = this.searchPage?.config()?.config?.routing as any;
    const router = routing?.router;
    if (!instance?.setUiState || !router) {
      return;
    }

    instance.setUiState(router.read());

  }

}
